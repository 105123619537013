@import 'color';

@each $color, $value in $colors {
  .#{$color} {
    color: $value;
  }
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.d-none {
  display: none;
}

/**
 * SECTION: cursor
 */

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.not-allow, .not-allowed {
  cursor: not-allowed;
}

/**
 * SECTION: text
 */

.oneline-text {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.twoline-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.threeline-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-capitalize, .capitalize {
  text-transform: capitalize;
}

.text-lowercase, .lowercase {
  text-transform: lowercase;
}

.text-uppercase, .uppercase {
  text-transform: uppercase;
}

/**
 * SECTION: color
 */

.black {
  color: $NeutralBlack;
}

.bg-black {
  background-color: $NeutralBlack;
}

.white {
  color: $NeutralWhite;
}

.bg-white {
  background-color: $NeutralWhite;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

/**
 * SECTION: position
 */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.w-full {
  width: 100%;
}

.break-all {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

.min-w-100 {
  min-width: 100px;
}

.min-w-150 {
  min-width: 150px;
}

.min-w-200 {
  min-width: 200px;
}

.wp-no-wrap {
  white-space: nowrap;
}

.wp-pre-wrap {
  white-space: pre-wrap;
}
