@import '/src/styles/variables';
@import '/src/styles/mixin';

.header {
  height: 70px;
  min-height: 70px;
  background-color: $NeutralWhite;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  @include box-shadow(0px 2px 3px rgba(0, 0, 0, 0.1));

  :global(.anticon-down) {
    @include transition(all .2s linear);
  }
  :global(.ant-dropdown-open) {
    :global(.anticon-down) {
      @include transform(rotateX(180deg));
    }
  }
}

.toggleIcon {
  font-size: 1.25em;
  cursor: pointer;
}

.user {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
}

.userAvt {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  margin-right: .5em;
}

.userName {
  padding-right: 2em;
}
