/** ======= COLOR ======= */
$NeutralWhite: #FFFFFF;
$NeutralBlack: #000000;

$WhiteTextShadow: #5c5c5c;
$ImgBgColor: #d1d1d1;
$ActiveBlue: #1967d2;

// Gray
$DarkGray: #333;
$LightGray: #d4d4d4;
$NeutralGray: #525252;
$LightGrayExtra: #fafafa;
$NeutralGray600: #9eb3c9;
$MidGrey: #666666;

// Red
$Red: #cf1322;

// Main color palette
$BorderColor: #d4d4d4;
$PrimaryColor: #053c76;
$ActiveBgColor: #1890ff;
$SecondaryColor: #123865;
$ActiveSwitchColor: #51ba62;


/** ======= FONTS ======= */
$regularWeight: 400;
$mediumWeight: 500;
$semiBoldWeight: 600;
$boldWeight: 700;


/** ======= SCREEN ======= */
$screenXLarge: 1920px;
$screenLarge: 1280px;
$screenMedium: 1024px;
$screenSmall: 768px;
$screenExtraSmall: 375px;

$modalFullScreenBodyHeight: calc(100vh - 110px);
$modalFullScreenInnerBodyHeight: calc(100vh - 110px - 48px);
