@import '/src/styles/mixin';

/* Animation */
@include keyframes(spinner) {
  0% {
    @include transform(rotate(0deg));
  }
  100% {
    @include transform(rotate(360deg));
  }
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 9000;
  height: 2em;
  width: 2em;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  /* Transparent Overlay */
  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
  }

  /* :not(:required) hides these rules from IE9 and below */
  &:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }

  &:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    border-radius: 0.5em;

    @include animation(spinner 1500ms infinite linear);
    @include box-shadow(
      rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
      rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0,
      rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
      rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0,
      rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
      rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0,
      rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
      rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0
    );
  }
}

