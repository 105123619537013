.errorMessage {
  color: red;
  font-size: 14px;
  opacity: 1;
  visibility: visible;
  transition: visibility 0.3s, opacity 0.3s ease-in-out;

  &.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s ease-in-out;
  }

  &.show {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.3s, opacity 0.3s ease-in-out;
  }
}
