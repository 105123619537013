@import '/src/styles/variables';

.sidebar {
  background: $PrimaryColor;
  :global(.ant-layout-sider-children) {
    display: flex;
    flex-direction: column;
  }
}

.sidebarLogo {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $NeutralWhite;
  line-height: 1em;
  font-weight: bold;
  font-size: 1.5em;
  background-color: $SecondaryColor;
  white-space: nowrap;
}

.sidebarMenu {
  flex: 1;
  overflow: auto;
  max-height: calc(100vh - 70px);
  background-color: $PrimaryColor !important;

  & ul {
    background-color: $SecondaryColor !important;
  }

  .sidebarMenuItem {
    &.active,
    &.active:hover,
    &:global(.ant-menu-item:hover) {
      background-color: $ActiveBgColor !important;
    }

    &.active > span > a,
    &.active > span > svg {
      color: $NeutralWhite !important;
    }
  }
}
