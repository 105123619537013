.infiniteScroll {
  height: 100%;
  overflow: auto;

  // .infinite-scroll-component__outerdiv {
  //   .infinite-scroll-component {
  //     align-self: start;
  //   }
  // }
}
