.border-none {
  border: none !important;
}

.border-r_base {
  border-radius: 4px;
}

.border-r_2 {
  border-radius: 2px;
}

.border-r_4 {
  border-radius: 4px;
}

.border-r_8 {
  border-radius: 8px;
}

.border-r_none {
  border-radius: 0px !important;
}
